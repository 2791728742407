<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>
</script>

<template>
  <template v-if="variant === 'default'">
    <div class="text-2xl sm:text-3xl">
      ¿Está buscando planes de seguro Medicare? Un agente de seguros licenciado puede verificar su elegibilidad.
    </div>

    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
    >
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">Planes Medicare Advantage</div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">Planes Medicare Advantage D-SNP</div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          Planes de Medicamentos Recetados de Medicare Parte D
        </div>
      </div>
    </div>

    <FormButtonNext>Comenzar</FormButtonNext>
  </template>

  <template v-if="variant === 'groceryAllowance900'">
    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-6"
    >
      <div class="text-2xl sm:text-3xl">
        Veamos si califica para un Plan Medicare Advantage con hasta $900
        en Asignación para Alimentos Saludables°
      </div>

      <FormButtonNext>Ver si califico</FormButtonNext>
    </div>
  </template>

  <template v-if="variant === 'spendingAllowances3300'">
    <div class="text-2xl sm:text-3xl">
      Vea si califica para beneficios adicionales en un Plan Medicare Advantage que puede incluir hasta:
    </div>

    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
    >
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $1,200 de reembolso en su cheque del Seguro Social*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $1,600 de asignación para artículos de venta libre*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $900 en tarjeta de débito prepagada para alimentos saludables*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $400 de asignación para anteojos o lentes de contacto*
        </div>
      </div>
    </div>

    <FormButtonNext>Ver si califico</FormButtonNext>
  </template>
</template> 