<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>

const { value: wantBenefit } = useField('wantBenefit')
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center">
    <template v-if="variant === 'default'">
      ¿Desea buscar planes Medicare Advantage con beneficios adicionales?
    </template>
    <template v-if="variant === 'groceryAllowance900'">
      ¿Desea hasta $900 para usar en comestibles?
    </template>
    <template v-if="variant === 'spendingAllowances3300'">
      ¿Desea hasta $3,300 en asignaciones para gastos?
    </template>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = true"
    >
      Sí
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantBenefit = null"
    >
      Prefiero no decir
    </Button>
  </div>
</template> 