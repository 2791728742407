<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>
</script>

<template>
  <template v-if="query.typ === 'clicks'">
    <div class="text-3xl sm:text-4xl text-center">
      ¡Vea si puede obtener beneficios adicionales con un Plan Medicare Advantage!
    </div>

    <MediaAlphaAd :user="{}" />
  </template>

  <template v-else-if="query.typ === 'cards'">
    <Typ name="cards" />
  </template>

  <template v-else-if="variant === 'default'">
    <div class="text-3xl sm:text-4xl text-center">
      ¡Vea si puede inscribirse en un plan Medicare Advantage con beneficios adicionales!
    </div>

    <div class="flex items-center justify-center space-x-3">
      <div class="relative flex w-2 h-2">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
        </div>
      </div>
      <div class="sm:text-lg font-medium">
        AGENTES DE SEGUROS LICENCIADOS DISPONIBLES
      </div>
    </div>

    <ClickToCall variant="red">
      <template #text>
        <span class="text-2xl sm:text-4xl"> {{ phone.formatted }} </span>
        <br />
        <span class="text-lg sm:text-3xl font-normal">
          HAGA CLIC PARA LLAMAR (GRATIS)
        </span>
      </template>
    </ClickToCall>

    <div
      class="text-2xl sm:text-4xl leading-7 font-bold uppercase tracking-wider text-center text-gray-500"
    >
      24/7, USUARIOS TTY 711
    </div>
    <div class="text-base text-center">Sin obligación de inscribirse</div>
  </template>

  <template v-else-if="variant === 'groceryAllowance900'">
    <div class="text-3xl sm:text-4xl text-center">
      ¡Vea si califica para un plan Medicare Advantage con hasta $900 en
      asignación para comestibles saludables!
    </div>

    <div class="flex items-center justify-center space-x-3">
      <div class="relative flex w-2 h-2">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
        </div>
      </div>
      <div class="sm:text-lg font-medium">
        AGENTES DE SEGUROS LICENCIADOS DISPONIBLES
      </div>
    </div>

    <ClickToCall variant="red">
      <template #text>
        <span class="text-2xl sm:text-4xl"> {{ phone.formatted }} </span>
        <br />
        <span class="text-lg sm:text-3xl font-normal">
          HAGA CLIC PARA LLAMAR (GRATIS)
        </span>
      </template>
    </ClickToCall>

    <div
      class="text-2xl sm:text-4xl leading-7 font-bold uppercase tracking-wider text-center text-gray-500"
    >
      24/7, USUARIOS TTY 711
    </div>
  </template>

  <template v-else-if="variant === 'spendingAllowances3300'">
    <div class="text-3xl sm:text-4xl text-center">
      ¡Vea si califica para un plan Medicare Advantage con hasta $3,300 en
      asignaciones para gastos!
    </div>

    <div class="flex items-center justify-center space-x-3">
      <div class="relative flex w-2 h-2">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
        </div>
      </div>
      <div class="sm:text-lg font-medium">
        AGENTES DE SEGUROS LICENCIADOS DISPONIBLES
      </div>
    </div>

    <ClickToCall variant="red">
      <template #text>
        <span class="text-2xl sm:text-4xl"> {{ phone.formatted }} </span>
        <br />
        <span class="text-lg sm:text-3xl font-normal">
          HAGA CLIC PARA LLAMAR (GRATIS)
        </span>
      </template>
    </ClickToCall>

    <div
      class="text-2xl sm:text-4xl leading-7 font-bold uppercase tracking-wider text-center text-gray-500"
    >
      24/7, USUARIOS TTY 711
    </div>
  </template>
</template> 