<script setup lang="ts">
const { onSubmit } = inject('form') as any
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center">
    Por favor espere mientras reviso sus respuestas...
  </div>

  <Checklist
    :items="[
      {
        text: 'Vive en EE.UU.',
        icon: 'check',
      },
      {
        text: 'Tiene Medicare Partes A y B',
        icon: forms.go4Benefits?.haveMedicare ? 'check' : 'x',
      },
      {
        text: 'Tiene Medicaid',
        icon: forms.go4Benefits?.haveMedicaid ? 'check' : 'x',
      },
    ]"
    @done="onSubmit()"
  />
</template> 