<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const isMobile = useIsMobile()

const router = useRouter()

const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = 900
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const isMobileOrTablet = ref(true)

onMounted(() => {
  isMobileOrTablet.value = window.innerWidth < 1024
})

const destination = computed(() => {
  let domain = ''

  let path =
    isWorkingHours.value && isMobile.value
      ? '/additional-social-security-call-now'
      : '/ads'

  const params = new URLSearchParams()

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Your Social Security ss1',
  category: 'LP',
  product: 'medicare',
})

const shrinkClass = ref('')
const onTypewriterDone = () => {
  shrinkClass.value = ' !w-[190px]'
}
</script>
<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'bridge' : '2025'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div
      class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
    >
      <span
        class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
      >
        Ad
      </span>

      <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
        <div
          class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
        >
          GET YOUR
          <span class="text-6xl md:text-7xl text-yellow">
            {{ USDollarFormatter(0).format(output) }}/yr+
          </span>
          Back On Social Security Yet?
        </div>

        <div
          class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
        >
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'no',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              No
            </span>
          </Button>
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'yes',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              Yes
            </span>
          </Button>
        </div>

        <img
          src="../../assets/get-yours-ss1-m.png"
          width="310"
          height="310"
          alt="Two smiling elderly men holding a paper in front of a building named 'Senior Center US'"
          class="mx-auto"
        />
      </div>
    </div>
    <div class="bg-black text-white">
      <div class="container space-y-2 py-2">
        <div class="text-sm text-center"> Turning 65? </div>
        <div class="text-center text-gray-400"> MULTI-PLAN_HLDAD1224324_M </div>
      </div>
    </div>
    <div
      v-if="brand.id === 'bridge'"
      class="container space-y-8 !max-w-3xl py-8"
    >
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-1.gif"
          width="720"
          height="300"
          alt="Two fridge images side by side, the first one is empty and the one on the right full of groceries"
        />
      </Button>
      <h1 class="text-4xl font-bold">
        Seniors: Don't Miss Out on These Incredible Grocery Assistance Programs!
      </h1>
      <h2>
        Nourish Your Golden Years—Explore Essential Grocery Assistance Programs
        Just for You!
      </h2>
      <img
        src="../../assets/emily-grocery-2.png"
        width="716"
        height="464"
        alt="Emily on a parking lot holding grocery bags"
      />

      <p class="text-lg">
        Think grocery assistance is only for those in dire need? Think again!
        Even if you're managing well, these programs can make a huge difference,
        helping you save money for other essentials while ensuring you have
        nutritious food. The reality is, you've worked hard throughout your
        life, and you deserve to enjoy your golden years without worrying about
        grocery costs. From SNAP benefits to Meals on Wheels, these programs are
        designed to keep you healthy, happy, and thriving—regardless of your
        income level.
      </p>
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-3.gif"
          width="1728"
          height="864"
          alt="Advertisement with the question: Have you claimed your grocery allowance yet?"
        />
      </Button>
      <h3> 1. Supplemental Nutrition Assistance Program (SNAP) </h3>
      <p class="text-lg">
        SNAP is the largest federal nutrition aid program, offering eligible
        seniors a monthly benefit to buy groceries. The benefit amount is
        determined by your income, household size, and other factors. You can
        use these benefits to purchase fresh produce, meats, dairy products, and
        more at authorized retailers.
      </p>

      <h3> 2. Meals on Wheels</h3>
      <p class="text-lg">
        Meals on Wheels delivers hot, nutritious meals straight to your door,
        ensuring you get the necessary daily nutrients. This service is
        especially helpful if you have limited mobility or find cooking
        difficult. Many local programs also provide additional services, like
        safety checks and social visits. Learn more about Meals on Wheels.
      </p>

      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-4.png"
          width="716"
          height="808"
          alt="Advertisement with the question: Have you claimed your grocery allowance yet? and image of a black allowance card"
        />
      </Button>
      <h3> 3. The Emergency Food Assistance Program (TEFAP)</h3>
      <p class="text-lg">
        TEFAP offers emergency food support to low-income individuals, including
        seniors. Through TEFAP, you can access free food items like canned
        goods, pasta, and other pantry staples distributed by local food banks
        and community organizations.
      </p>

      <h3> 4. Senior Farmers' Market Nutrition Program (SFMNP) </h3>
      <p class="text-lg">
        This program provides eligible seniors with coupons that can be used to
        buy fresh fruits, vegetables, and herbs at farmers' markets, roadside
        stands, and community-supported agriculture locations. It's a great way
        to enjoy fresh, seasonal produce while supporting local farmers.
      </p>
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-5.png"
          width="716"
          height="938"
          alt="Advertisement for people on medicare with the image of a blue allowance card"
        />
      </Button>

      <h3> 5. Commodity Supplemental Food Program (CSFP) </h3>
      <p class="text-lg">
        CSFP offers monthly food packages to low-income seniors, filled with
        nutritious items like cereals, canned fruits and vegetables, and
        proteins. These packages are designed to supplement your diet with
        essential nutrients that you may not be getting otherwise.
      </p>
      <hr class="border-zinc-300" />
      <p class="text-lg">
        These programs aim to help you maintain a healthy diet and improve your
        overall well-being. Don't hesitate to apply or reach out to your local
        community services for assistance — you've earned it!
      </p>

      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/emily-grocery-3.gif"
          width="1728"
          height="864"
          alt="Advertisement with the question: Have you claimed your grocery allowance yet?"
        />
      </Button>
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}

.custom-transition {
  transition: width 0.5s;
}

.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}

@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
